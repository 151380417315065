export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~5],
		"/about": [~7],
		"/back": [8,[2]],
		"/back/aiList": [9,[2]],
		"/back/articleClassificationList": [10,[2]],
		"/back/articleList": [11,[2]],
		"/back/domainList": [12,[2]],
		"/back/referenceList": [13,[2]],
		"/catalog/[slug]": [~14],
		"/catalog/[slug]/page/[slug]": [~15],
		"/login": [16],
		"/privacy": [~17],
		"/register": [18],
		"/view2": [~21,[4]],
		"/view2/contents": [22,[4]],
		"/view": [~19,[3]],
		"/view/contents": [20,[3]],
		"/[slug]": [~6]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';